(function ($) {
    $('.js-gallery').slick({
        arrows: true,
        dots: false,
        fade: true,
        autoplay: false,
        autoplaySpeed: 4000,
        speed:1000,
        pauseOnHover:false
    });
})(jQuery);
