(function ($) {
    var d = new Date(new Date().getTime());
    var t = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    var today = d.getDate() + "/" + ('0' + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear();
    var tomorrow = t.getDate() + "/" + ('0' + (t.getMonth() + 1)).slice(-2) + "/" + t.getFullYear();

    //FASTBOOKER
    var arrival = $('.startdate');
    var departure = $('.enddate');

    arrival.datepicker({
        format: 'dd/mm/yyyy',
        autoclose: true,
        startDate: 'd',
        weekStart: 1
    }).on('changeDate', function (e) {

        $(this).parent().next().find(departure).focus();
        //departure.focus();
        var selectedDate = $(this).datepicker('getDate');
        var nextDay = new Date(selectedDate);
        nextDay.setDate(nextDay.getDate() + 1);
        $(this).parent().next().find(departure).datepicker('setDate', nextDay);
        $(this).parent().next().find(departure).datepicker('setStartDate', nextDay);
    });
    departure.datepicker({
        format: 'dd/mm/yyyy',
        autoclose: true,
        startDate: tomorrow,
        weekStart: 1
    });

    var btn = $('.btn-fastbooker');
    btn.click(function (e) {
        e.preventDefault();
        var form = $(this).parents('form:first');
        var arrival = form.find('.startdate').val();
        arrival = arrival.split('/');
        arrival = arrival[2] + '-' + arrival[1] + '-' + arrival[0];
        var departure = form.find('.enddate').val();
        departure = departure.split('/');
        departure = departure[2] + '-' + departure[1] + '-' + departure[0];
        var href = "https://reservations.cubilis.eu/jose-hotel-blankenberge?Language=" + getCubilisLang($('html').attr('lang'));
        if ((arrival !== 'undefined-undefined-') && (departure !== 'undefined-undefined-')) {
            href = "https://reservations.cubilis.eu/jose-hotel-blankenberge/Rooms/Select?Language=" + getCubilisLang($('html').attr('lang')) + "&Arrival=" + arrival + "&Departure=" + departure;
        }
        window.open(href, '_blank');
    });


    function getCubilisLang(lang)
    {
        var cubilisLang = 'en-GB';
        if (lang === 'nl') {
            cubilisLang = 'nl-NL';
        } else if (lang === 'fr') {
            cubilisLang = 'fr-FR';
        } else if (lang === 'de') {
            cubilisLang = 'de-DE';
        }
        return cubilisLang;
    }

    function openFastbooker() {
        $(".booker").removeClass("restobook");
        $(".booker").addClass("active");
        $(".booker").addClass("fastbook");
    }

    function closeFastbooker() {
        $(".booker").removeClass("fastbook");
        $(".booker").removeClass("active");
    }
})(jQuery);
